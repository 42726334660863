import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { subscribeGameHistory, getGameHistory } from "@actions/gameInfoAction";
import GameHistoryTable from "@components/gameHistory/gameHistoryTable";
import { FormattedMessage } from "react-intl";
import GameHistoryLastResult from "@components/gameHistory/gameHistoryLsatresult";
import GameHistoryHeader from "@components/gameHistory/gameHistoryHeader";

function GameHistory({ history, isConnected, subscribeGameHistory, gameCondition, getGameHistory, DragonWinPercent, TigerWinPercent, TiePercent, SuitedTiePercent }) {
    const [limit] = useState(84);
    const [skip] = useState(0);


    useEffect(() => {
        if (isConnected) {
            subscribeGameHistory()
            getGameHistory(limit, skip)
        }
    }, [isConnected])

    useEffect(() => {
        if (isConnected) {
            getGameHistory(limit, skip)
        }
    }, [limit, skip, gameCondition])

    return (
        <div className="game-history">
            <div className="game-history-last-results">
                {history.slice(0, 10).map((item, index) => <GameHistoryLastResult round={item} key={index} />)}
            </div>
            <GameHistoryHeader history={history} />
            <div className="game-history-data">
                <div className="info-box">
                    <div className="info-box-item"><FormattedMessage id="dragon" /> <span className="divider">-</span> <span className="percent">{DragonWinPercent}%</span> </div>
                    <div className="info-box-item"><FormattedMessage id="tiger" /> <span className="divider">-</span> <span className="percent">{TigerWinPercent}%</span></div>
                    <div className="info-box-item"><FormattedMessage id="Tie" /> <span className="divider">-</span> <span className="percent">{TiePercent}%</span></div>
                </div>
                <GameHistoryTable history={history} limit={limit} />
            </div>
        </div>
    )
}

const mapDispatchToProps = (state) => {
    return {
        isConnected: state.globalInfo.isConnected,
        gameCondition: state.gameCondition,
        history: state.gameHistory.history,
        DragonWinPercent: state.gameHistory.DragonWinPercent,
        TigerWinPercent: state.gameHistory.TigerWinPercent,
        TiePercent: state.gameHistory.TiePercent,
        SuitedTiePercent: state.gameHistory.SuitedTiePercent
    }
}

const mapPropsToState = {
    subscribeGameHistory,
    getGameHistory
}

export default connect(mapDispatchToProps, mapPropsToState)(GameHistory);