import React from "react";
import BetArea from "@components/betBoard/betArea";
import classNames from "classnames";

function BetBoard({threeD}) {
    return (
        <div className={classNames("bet-board", {'three-d-size':threeD})}>
            <BetArea threeD={threeD}/>
        </div>
    )
}

export default BetBoard;