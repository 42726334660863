import React, { useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {LoadingIcon} from "@resource";
import {subscribeVideoStream} from "@actions/gameAction";

function VideStream({videoStreamConfig, inactivity, subscribeVideoStream}) {
    const [loading, setLoading] = useState(false);
    const Player = useRef()


    useEffect(() => {
        subscribeVideoStream()
    }, [])

    useEffect(() => {
        if (videoStreamConfig && videoStreamConfig.Live) {
            const serverConfig =  JSON.parse(videoStreamConfig.Live);
            const config =  {
                "source": {
                    "startIndex": videoStreamConfig.GroupId ? 1 : 0,
                },
                "style": {
                    "controls": false,
                    "view": false,
                    "width": "100%",
                    "height": "100%",
                    "displayMutedAutoplay": true
                },
                events: {
                    onPlay: function (e) {
                        setLoading(false)         
                    },
                    onPause: function (e) {
                        setLoading(true)
                    },
                    onLoading: function (e) {
                        setLoading(true)
                    },
                    onError: function (e) {
                        setLoading(true)
                    },
                    onDestroy: function (e) {
                        setLoading(true)
                    }
                },
                "playback": {
                    "autoplay": true,
                    "automute": true,
                    "muted": true
                },
            }


            if(videoStreamConfig.GroupId) {
                config.source.group = {
                    "id": videoStreamConfig.GroupId, // your stream group id
                    "security": {
                        "jwtoken": serverConfig.data.token // your JSON Web Token
                    }
                }
                config.source.options = {
                    "adaption": {
                     "rule": "deviationOfMean2"
                    }
                 }
                 config.playback.latencyControlMode = "balancedadaptive"
            } else {
                config.source.defaults ={
                    "service": "bintu"
                }
                config.source.entries = [
                    {
                        "index": 0,
                        "h5live": {
                            "server": {
                                "websocket": process.env.REACT_APP_STREAM_WEB_SOCKET,
                                "hls": process.env.REACT_APP_STREAM_HLS
                            },
                            "rtmp": {
                                "url": process.env.REACT_APP_STREAM_COMMON_URL,
                                "streamname": videoStreamConfig.StreamName
                            },
                            ...serverConfig.h5live
                        }
                    }
                ]
            }
            Player.current = new window.NanoPlayer('video-stream')
            Player.current.setup(config).then(function (config) {}, function (error) {
                console.error(error.message);
            });
        }
    }, [videoStreamConfig])
    
    useEffect(() => {
        if (Player.current) {
            if (inactivity.suspend) {
                Player.current.pause()
            }
        }
        
    },[inactivity.suspend])

    return(<div className="video-stream"  >
        <div id="video-stream" />
        { loading ? <div className="video-stream-loading"><LoadingIcon/> </div> : ""}
    </div>)
}

const mapStateToProps = (state) => {
    return {
        videoStreamConfig: state.videoStreamConfig,
        inactivity: state.inactivity,
    }
}

const mapPropsToState = {
    subscribeVideoStream
}

export default connect(mapStateToProps, mapPropsToState)(VideStream)