import {useEffect, useState} from "react";

export default function useResizeHook() {
    const [mobile, setMobile] = useState();
    const [tab, setTab] = useState();
    const [pc, setPc] = useState();
    const [middlePc, setMiddlePc] = useState();
    const [smallPc, setSmallPc] = useState();
    const [height, setHeight] = useState();
    const [width, setWidth] = useState();

    useEffect(() => {
        function detectResize() {
            const deviceWidth = document.documentElement.clientWidth, deviceHeight = document.documentElement.clientHeight;
            let deviceMobile = false, deviceTab = false, devicePc = false,  deviceSmallPc = false, deviceMiddlePc = false

            if (deviceWidth <= 540) {
                devicePc = false;
                deviceMobile = true;
                deviceTab = false;
                deviceSmallPc = false;
                deviceMiddlePc= false;
            } else if (deviceWidth > 540 && deviceWidth <= 768 ){
                devicePc = false;
                deviceMobile = false;
                deviceTab = true;
                deviceSmallPc = false;
                deviceMiddlePc = false;
            } else if (deviceWidth > 768 && deviceWidth <= 1280 ){
                devicePc = false;
                deviceMobile = false;
                deviceTab = false
                deviceSmallPc = true
                deviceMiddlePc = false
            } else if (deviceWidth > 1280 && deviceWidth <= 1470 ){
                devicePc = false;
                deviceMobile = false;
                deviceTab = false;
                deviceSmallPc = false;
                deviceMiddlePc = true
            } else {
                devicePc = true;
                deviceMobile = false;
                deviceTab = false;
                deviceMiddlePc = false;
                deviceSmallPc = false
            }
            setMobile(deviceMobile)
            setTab(deviceTab)
            setPc(devicePc)
            setSmallPc(deviceSmallPc)
            setMiddlePc(deviceMiddlePc)
            setWidth(deviceWidth)
            setHeight(deviceHeight)
        }
        detectResize()
        window.addEventListener("resize",  detectResize, true)
        return () => {
            window.removeEventListener("resize", detectResize, true)
        }
    }, [])

    return {mobile, tab, pc, height, width, smallPc, middlePc}
}