import {DefaultLanguageCode} from "@constants/Enums";

const store = {
    settings: {
        lang: DefaultLanguageCode
    },
    emptyObj: {},
    emptyArr: [],
    globalInfo: {
        isConnected: false,
        session:"",
        lobbyUrl: "",
        detectNewLogin: false
    },
    totalBets: 0,
    winnerList: [], // getWinnerList(),
    history: {
        data: [],
        hasNext: false,
    },
    odd: {
        0: null,
        1: "1:1",
        2: "1:1",
        3: "11:1",
        4: "50:1",
      
    },
    userBetList: [ ],
    cards: {
        dragon: {},
        tiger: {}
    },
    enabledBetAreas: false,
    timeTicker: {
        diff: 0,
        start: 0,
        end: 0
    },
    gameHistory: {
        history: [],
        DragonWinPercent: 0,
        TigerWinPercent: 0,
        TiePercent: 0,
        SuitedTiePercent: 0
    },
    userInfo: {
        Balance: 0,
        Currency: "",
        Id: "",
        Image: null,
        Name: ""
    },
    videoStreamConfig: {
        Live: null,
        StreamName: ""
    },
    limit: {
        Max: 0,
        Min: 0,
        Nominal: []
    },
    selectedBoard: {
        board: -1,
        odd:0
    },
    inactivity: {
        count : 0,
        suspend: false
    },
    loadInfo: {
        "prediction-1": '',
        "prediction-2": '',
        "prediction-3": '',
        "prediction-4": ''
    }
}

export default store;

function getWinnerList() {
    let result = [];
    for (let i = 0; i < 100; i++) {
        result.push({
            PlayerName: (Math.random() + 1).toString(36).substring(Math.random() * 10) + i,
            WonAmount: Math.round(Math.random() * 100000)
        })
    }
    return result
}