import React from "react";
import CardsListItem from "./CardsListItem";

export function CardList({card, title}) {
    return(
        <div className="cards-element">
             <div className="title">{title}</div>
            <CardsListItem card={card} />
        </div>
    )
}

export default CardList