import {
    SET_CARDS,
    SUB_GAME_FINISH,
    RESET_USER_BET_LIST,
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SUB_CANCEL_GAME,
    SET_TIME_TICKER,
    SET_ALERT_MSG,
    REQUEST_REFRESH_SESSION,
    REQUEST_GAME_START,
    CONNECT_TO_SOCKET,
    SUB_GAME_INIT,
    SET_RULES ,
    SET_USER_INFO,
    SET_VIDEO_STREAM_CONFIG,
    SUB_VIDEO_STREAM,
    SET_DRAGON_CARD,
    SET_TIGER_CARD,
    SUB_ROUND_START,
    RESET_ACTIVE_BET,
    SET_LIMIT,
    SET_DISABLE_BETS_AREAS,
    SUB_GAME_RESULT,
    SET_USER_WON,
    SET_WINNER_LIST,
    SET_LOAD_INFO,
    SET_ALL_BETS
} from "@actions/actionTypes";
import { CardSide, CardsSuit } from "@constants/Enums";
import initialStore from "../initialStore";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@services/storageService";

export function subscribeGameFinish() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_GAME_FINISH,
            handle: (data) => {
                const {userInfo, roundId} = getState();
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`)
                dispatch({ type: RESET_ACTIVE_BET, roundId: getState().roundId })
                dispatch({ type: RESET_USER_BET_LIST });
                dispatch({ type: SET_GAME_CONDITION, condition: false });
                dispatch({ type: SET_ROUND_START, roundId: "" });
                dispatch({ type: SET_TIGER_CARD, card: initialStore.emptyObj });
                dispatch({ type: SET_DRAGON_CARD, card: initialStore.emptyObj });
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: false});
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
            }
        })
    }
}


export function subscribeVideoStream() {
    return dispatch => {
        dispatch({
            eventOn: SUB_VIDEO_STREAM,
            handle: (data) => {
                dispatch({ type: SET_VIDEO_STREAM_CONFIG, config: data });
            }
        })
    }
}

export function subscribeCanceledGame() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_CANCEL_GAME,
            handle: () => {
                dispatch({ type: RESET_ACTIVE_BET, roundId: getState().roundId });
                dispatch({ type: SET_CARDS, card: [] });
                dispatch({ type: SET_ROUND_START, roundId: "" });
                dispatch({ type: SET_TIGER_CARD, card: initialStore.emptyObj });
                dispatch({ type: SET_DRAGON_CARD, card: initialStore.emptyObj });
                dispatch({ type: SET_ALERT_MSG, data: { type: 'info', messages: "game_is_canceled_your_bets_will_refund" } });
                dispatch({
                    type: SET_TIME_TICKER, ticker: {
                        Start: initialStore.timeTicker.start,
                        End: initialStore.timeTicker.end
                    }
                });
                dispatch({ type: RESET_USER_BET_LIST });
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: false});
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`)
            }
        })
    }
}

export function refreshSession(session) {
    return dispatch => {
        dispatch({
            type: REQUEST_REFRESH_SESSION,
            session
        })
    }
}

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(session) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            session
        })
    }
}

export function subGameInitData() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_INIT,
            handle: (data) => {
                let unAcceptedBets = [], bets = []
                var userBookData = [];
                dispatch({ type: SET_TIME_TICKER, ticker: { Start: data.Round?.Start || 0, End: data.Round?.End || 0 } });
                dispatch({ type: SET_ROUND_START, roundId: data.Round?.RoundNumber || "" });
                dispatch({ type: SET_USER_INFO, user: data.User });
                if (data.Rules && data.Rules[0]) {
                    dispatch({type: SET_RULES, rules: data.Rules[0] })
                }
                dispatch({type: SET_LIMIT, limit: data.GameLimitsDto})
                if (data.Round?.RoundParticipants?.length > 0) {
                    data.Round.RoundParticipants.forEach(card => {

                        if (card.ParticipantId === CardSide["dragon"]) {
                            dispatch({ type: SET_DRAGON_CARD, card: { CardNumber: card.CardNumber, Suit: card.CardSuit } })
                        } else {
                            dispatch({ type: SET_TIGER_CARD, card: { CardNumber: card.CardNumber, Suit: card.CardSuit } })
                        }
                    })
                }
                if (data.User.Id && data.Round?.RoundNumber) {
                    unAcceptedBets = getLocalStorage(`unAcceptedBets_${data.User.Id}_${ data.Round.RoundNumber}`) || []
                }
                if (data.UserBets?.length) {
                    data.UserBets.forEach(bet => {
                        bets.push({
                            accepted: true,
                            Prediction: bet.BetEventId,
                            Stake: bet.Stake,
                            Odd: bet.Odd})
                    })
                    bets.forEach(item => {
                        userBookData['prediction-'+item.Prediction] = item.Stake;
                    })
                    dispatch({type: SET_LOAD_INFO, load: userBookData || initialStore.loadInfo});
                }
                if (data.Round?.RoundNumber && data.User?.Id) {
                    setLocalStorage(`acceptedBets_${data.User.Id}_${data.Round.RoundNumber}`, bets)
                }
                dispatch({type: SET_ALL_BETS, bets: [...unAcceptedBets, ...bets], startTime:  data.Timer?.Start});
                if(data.ServerTime && data.Round?.End && data.ServerTime < data.Round.End) {
                    dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: true})
                }
                dispatch({
                    type: SET_CARDS,
                    cards: data.Round?.RoundParticipants?.length > 0 ? data.Round?.RoundParticipants : []
                });
            }
        })
    }
}

export function subscribeRoundStartAction() {
    return dispatch => {
        dispatch({
            eventOn: SUB_ROUND_START,
            handle: (data) => {
                dispatch({ type: SET_TIME_TICKER, ticker: { Start: data.TimerStart || 0, End: data.TimerEnd || 0 } });
                dispatch({ type: SET_ROUND_START, roundId: data.RoundNumber });
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: true})
                dispatch({type: SET_GAME_CONDITION, condition : true});
            }
        });
    }
}

export function subscribeGameResult() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_RESULT,
            handle: (data) => {
                dispatch({type: SET_USER_WON, wonAmount: {value: data.WonAmount}})
                dispatch({type: SET_WINNER_LIST, winners: data.Results});
            }
        })
    }
}

export function setUserWon(wonAmount) {
    return dispatch => {
        dispatch({type: SET_USER_WON, wonAmount})
    }
}