import {SET_TIGER_CARD} from "@actions/actionTypes";
import initialStore from "../../initialStore";
import {CardsSuit, CardTypeMap} from "@constants/Enums";

function tigerCardReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_TIGER_CARD:
            if (action.card && action.card.CardNumber) {
                return {type: CardTypeMap[action.card.CardNumber], suit: CardsSuit[action.card.Suit], point: action.card.CardNumber}
            } else  {
                return action.card
            }
        default:
            return state;
    }
}

export default tigerCardReducer