import React from "react";
import CardsList from "@components/cards/CardsList";
import classNames from "classnames";

function CardList({ player, cards, winner = false }) {
    return (
        <div className={classNames("history-card-list", { active: winner })}>
            <div>
                <div className="player">{player}</div>
            </div>
            <div>
                <CardsList card={cards} className="history-card-list-view" />
            </div>
        </div>
    )
}

export default CardList