import { SET_DRAGON_CARD, SUB_CARD, SET_TIGER_CARD, SET_SOUND_KEY } from "@actions/actionTypes";
import { CardSide , CardTypeMap, CardsSuit} from "@constants/Enums";
import initialStore from "../initialStore";

export function subscribeCards() {
    return dispatch => {
        dispatch({
            eventOn: SUB_CARD,
            handle: (data) => {
                dispatch({type: SET_SOUND_KEY, payload: CardTypeMap[data.CardNumber] + CardsSuit[data.Suit]})
                if(data.Participant === CardSide["dragon"]) {
                    dispatch({type: SET_DRAGON_CARD, card: data})
                } else {
                    dispatch({type: SET_TIGER_CARD, card: data})
                }
            }
        })
    }
}

export function resetPlayingCards() {
    return dispatch => {
        dispatch({type: SET_DRAGON_CARD, card: initialStore.emptyObj})
        dispatch({type: SET_TIGER_CARD, card: initialStore.emptyObj})
    }
}