import React, { useEffect, useState } from "react";
import ToolTip from "@components/toolTip/toolTip";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import RoundInfoItem from "@components/history/RoundHistory/RoundInfoItem";
import CardList from "@components/history/RoundHistory/CardList";
import { FormattedMessage } from "react-intl";
import { CardsSuit, CardTypeMap, PlayerOddType } from "@constants/Enums";
import { ModalCloseIcon } from "@resource";

function GameHistoryLastResult({ round, ...restProps }) {
    const [activeRound, setActiveRound] = useState(false)
    const [dragonCards, setDragonCards] = useState([])
    const [tigerCards, setTigerCards] = useState([])

    useEffect(() => {
        if (round.Cards) {
            setDragonCards(round.Cards[0])
            setTigerCards(round.Cards[1])
        }
    },[round])

    const trigger = (isActive) => {
        setActiveRound(isActive)
    }

    const getContent = () => {
        return (
            <div className="game-history-last-results-item">
                <div className="game-history-last-results-item-header">
                    <div className="title">
                        <FormattedMessage id="round_id" />: {round.RoundId}
                    </div>
                    <button className="circled-button small">
                        <ModalCloseIcon id={`GameHistoryLastResult${round.RoundId}`} />
                    </button>
                </div>
                <div className="game-history-last-results-item-round-info">
                    <RoundInfoItem time={round.StartTime}
                        timeTitle="game_start" />
                    <RoundInfoItem time={round.EndTime}
                        timeTitle="game_end" />
                </div>
                <div className="game-history-last-results-item-round-cards">
                    <CardList player={<FormattedMessage id="dragon" />}
                        cards={{type: CardTypeMap[dragonCards.Number], suit: CardsSuit[dragonCards.Suit]}}
                        winner={round.BetEvent === PlayerOddType.Dragon || round.BetEvent === PlayerOddType.Tie || round.BetEvent === PlayerOddType.SuitedTie} />

                    <CardList player={<FormattedMessage id="tiger" />}
                        cards={{type: CardTypeMap[tigerCards.Number], suit: CardsSuit[tigerCards.Suit]}}
                        winner={round.BetEvent === PlayerOddType.Tiger || round.BetEvent === PlayerOddType.Tie || round.BetEvent === PlayerOddType.SuitedTie} />
                </div>
            </div>
        )
    }
    return (
        <ToolTip title={getContent()} position={"top"} type="popup" triggerCallBack={trigger}{...restProps}>
            <GameHistoryIcon won={round.BetEvent} type="large" round={round} active={activeRound} />
        </ToolTip>
    )
}

export default GameHistoryLastResult