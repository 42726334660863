import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
    subscribeBetResult,
    subscribeActiveBets,
    selectBoard,
    resetSelectBoard
} from "@actions/betsAction";
import { connect } from "react-redux";
import SmallBoard from "./BoardElements/smallBoard";
import LargeBoard from "./BoardElements/largeBoard";
import MediumBoard from "./BoardElements/MediumBoard";
import {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
} from "@actions/workerActions";

function BetArea({
    threeD,
    subscribeBetResult,
    subscribeActiveBets,
    enabledBetAreas,
    odd,
    selectBoard,
    selectedBoard,
    subscribeTickerWorker,
    unSubscribeTickerWorker,
    resetSelectBoard,
    loadInfo
}) {
    const [disabledAll, setDisabledAll] = useState(true);

    useEffect(() => {
        function getGameTimeDiff(e) {
            const timer = JSON.parse(e.data);
            if (!(timer.data && parseInt(timer.data / 1000) > 3)) {
                setDisabledAll(true);
            } else {
                setDisabledAll(false);
            }
        }
        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff);
        };
    }, []);

    useEffect(() => {
        subscribeBetResult();
        subscribeActiveBets();
    }, []);

    useEffect(() => {
        if (disabledAll) {
            resetSelectBoard();
        }
    }, [disabledAll]);

    return (
        <div className="board">
            <div
                className={classNames("board-info", {
                    threeD,
                    "desabled-bet-areas": !enabledBetAreas,
                })}
            >
                <SmallBoard
                    odd={odd}
                    selectBoard={selectBoard}
                    selectedBoard={selectedBoard.board}
                    disabledAll={disabledAll}
                />
                <LargeBoard
                    odd={odd}
                    selectBoard={selectBoard}
                    selectedBoard={selectedBoard.board}
                    disabledAll={disabledAll}
                />
                <MediumBoard
                    odd={odd}
                    selectBoard={selectBoard}
                    selectedBoard={selectedBoard.board}
                    disabledAll={disabledAll}
                />
            </div>
            <div className="book-show-top">
                <div className={`${loadInfo['prediction-3'] > 0 ? 'tie-book green' : ''}`}>{loadInfo['prediction-3'] > 0 ? loadInfo['prediction-3'] : ''}</div>
            </div>
            <div className="book-show-bottom">
                <div className={`${loadInfo['prediction-1'] > 0 ? 'dragon-book green' : ''}`}>{loadInfo['prediction-1'] > 0 ? loadInfo['prediction-1'] : ''}</div>
                <div className={`${loadInfo['prediction-2'] > 0 ? 'tiger-book green' : ''}`}>{loadInfo['prediction-2'] > 0 ? loadInfo['prediction-2'] : ''}</div>
                <div className={`${loadInfo['prediction-4'] > 0 ? 'suited-book green' : ''}`}>{loadInfo['prediction-4'] > 0 ? loadInfo['prediction-4'] : ''}</div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        enabledBetAreas: state.enabledBetAreas,
        odd: state.odd,
        selectedBoard: state.selectedBoard,
        loadInfo: state.loadInfo
    };
}

const mapDispatchToProps = {
    subscribeBetResult,
    subscribeActiveBets,
    unSubscribeTickerWorker,
    subscribeTickerWorker,
    selectBoard,
    resetSelectBoard
};

export default connect(mapStateToProps, mapDispatchToProps)(BetArea);
