import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import HistoryItem from "@components/history/historyItem";
import {FormattedMessage} from "react-intl";
import ElementLoading from "@components/elemetLoading";
import {NoDataIcon} from "@resource";
import {compareDate} from "@services/historyService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function HistoryList({history, setPage, loading, setSelectedRound, getHistoryItemValue}) {
    const bodyRef = useRef();

    const fetchMore = () => {
        let scrollArea = bodyRef.current.scrollTop + bodyRef.current.clientHeight;
        if (((bodyRef.current.scrollHeight > scrollArea - 2) && (bodyRef.current.scrollHeight < scrollArea + 2)) && history.hasNext && !loading) {
            setPage((prev) => prev + 1);
        }
    }

    var currentDate = new Date();
    var before7Daysdate = new Date(currentDate.setDate(currentDate.getDate() - 7));

    const [startDate, setStartDate] = useState(before7Daysdate);
    const [endDate, setEndDate] = useState(new Date());

    return (
        <div className="history-table">
            <div className="history-table-date-filter" >
                <div>
                    <DatePicker 
                        onChange={(date) => setStartDate(date)}
                        selected={startDate}
                        minDate={new Date(new Date().setMonth(new Date().getMonth() - 1))}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                         />
                </div>
                <div>
                    <DatePicker 
                        onChange={(date) => setEndDate(date)}
                        selected={endDate}
                        minDate={new Date(new Date().setMonth(new Date().getMonth() - 1))}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy" />
                </div>
            </div>
            {
                history && history.data && history.data.length ? <>
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                        <tr>
                            <th><FormattedMessage id="date" /></th>
                            <th><FormattedMessage id="bet" /></th>
                            <th><FormattedMessage id="win-lose" /></th>
                        </tr>
                        </thead>
                    </table>
                    <div className="history-table-body scrollable" ref={bodyRef} onScroll={fetchMore}>

                        <table cellSpacing="0" cellPadding="0">
                            <tbody>
                            {
                                history && history.data && history.data
                                .filter(row => {
                                    let filterPass = true
                                    const date = new Date(row.startTime)
                                    if (startDate) {
                                      filterPass = filterPass && (date >= new Date(startDate).setUTCHours(0,0,0,0))
                                    }
                                    if (endDate) {
                                      filterPass = filterPass && (date <= new Date(endDate).setUTCHours(23,59,59,999))
                                    }
                                    return filterPass
                                })
                                .map((item, index) => {
                                    const nexDay = history.data[index + 1]
                                    return <HistoryItem item={item} index={index} key={`${item.roundId}${index}`} nextDay={nexDay &&  !compareDate(nexDay.startTime, item.startTime)} onClick={() => setSelectedRound(item)} getHistoryItemValue={getHistoryItemValue}/>
                                })
                            }
                            </tbody>

                        </table>
                        {loading ? <ElementLoading /> : ""}
                    </div>
                </> : loading ? <ElementLoading /> : <div className="history-table-no-data">
                    <div className="icon">
                        <NoDataIcon />
                    </div>
                    <div className="title">
                        <FormattedMessage id="no-data" />
                    </div>

                </div>
            }
        </div>
    )
}
function mapStateToProps(state) {
    return {
        history: state.history,
        loading: state.historyLoading
    }
}

export default connect(mapStateToProps, null)(HistoryList);


