import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import Modal from "@components/modal";
import ElementLoading from "@components/elemetLoading";
import { ModalCloseIcon} from "@resource";

function Rules({rules, show, handleCancel}) {
    const rulesBlock = useRef();

    useEffect(() => {
        if (rules) {
            rulesBlock.current.innerHTML = rules.Text;
        }

    }, [rules])
    return(
        <Modal show={show}
               handleCancel={() => handleCancel(false)}
               parentClass={"rules"}
        >
            <div className="modal-header">
                <div className="modal-header-title">{rules.title || ""}</div>
                <div className="modal-header-control">
                    <button className="circled-button small" onClick={() => handleCancel(false)}>
                        <ModalCloseIcon/>
                    </button>
                </div>
            </div>
            <div ref={rulesBlock} className="modal-body">
                <ElementLoading />
            </div>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        rules: state.rules
    }
}


export default connect(mapStateToProps, null)(Rules)