import {getDateDDMMYYUtc} from "./timestampService";

export function getHistoryByDaySum(history) {
    const locHistory = history.sort(sortHistory);
    let convertingHistory = [];

    locHistory.forEach((item, index) => {
        const nexDay = locHistory[index + 1]
        if(nexDay &&  !compareDate(nexDay.CreateDate, item.CreateDate)) {
            convertingHistory.push({
                CreateDate : item.CreateDate,
                Stake: item.Stake,
                Win: item.Win,
                BetEventId: item.BetEventId,
                startDay: true
            })
        } else {
            convertingHistory.push({
                CreateDate : item.CreateDate,
                Stake: item.Stake,
                Win: item.Win,
                BetEventId: item.BetEventId,
            })
        }


    })

    return convertingHistory
}

export function sortHistory(a,b) {
    return new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime()
}


export function compareDate(dateA, dateB) {
    return getDateDDMMYYUtc(dateA) === getDateDDMMYYUtc(dateB)
}
