import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { ModalCloseIcon, BackRoundHistoryIcon} from "@resource"; //HistoryFilterIcon,
import HistoryList from "@components/history/HistoryList";
import classNames from "classnames";
import Modal from "@components/modal";
import {
    resetHistory,
    getHistory
} from "@actions/historyAction";
import RoundHistory from "./RoundHistory/RoundHistory";
import { FormattedMessage } from "react-intl";
import {BetsType, Prediction} from "@constants/Enums";


function History({handleCancel, show, resetHistory, getHistory}) {
    const [limit] = useState(30);
    const [page, setPage] = useState(0);
    const [selectedRound, setSelectedRound] = useState(null);

    useEffect(() => {
        if (!show) {
            setPage(1);
        } else {
            resetHistory();
        }
    }, [show])

    useEffect(() => {
        if (show) {
            fetchHistory();
        }
    }, [show, page]);



    const fetchHistory = () => {
        getHistory(limit, page)
    }

    const getHistoryItemValue = (element) => {

        return element.win;
    }
    

    return (
        <Modal show={show}
               handleCancel={() =>  handleCancel(false)}
               parentClass={"history"}
        >
            <div className="modal-header">
                <div className="modal-header-title">
                    { selectedRound && <>
                        <FormattedMessage id="round_id" />: {selectedRound.roundNumber}
                    </>}
                </div>
                <div className="modal-header-control">
                    {selectedRound && <button className={classNames("circled-button small")} onClick={() => setSelectedRound(null)}>
                        <BackRoundHistoryIcon/>
                    </button>}
                    <button className="circled-button small" onClick={() =>  handleCancel(false)}>
                        <ModalCloseIcon id="History"/>
                    </button>
                </div>
            </div>
            <div className="history">
                {selectedRound && <RoundHistory round={selectedRound} getHistoryItemValue={getHistoryItemValue}/> || <HistoryList setPage={setPage} fetchHistory={fetchHistory} setSelectedRound={setSelectedRound} getHistoryItemValue={getHistoryItemValue}/>}
            </div>
        </Modal>)

}

const mapDispatchToProps = {
    resetHistory,
    getHistory,

}

export default connect(null, mapDispatchToProps)( History);
