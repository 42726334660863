import {SET_GAME_HISTORY} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function gameHistoryReducer(state = initialStore.gameHistory, action) {
    switch (action.type) {
        case SET_GAME_HISTORY:
            return {
                history : action.gameHistory.Rounds.Items,
                DragonWinPercent: action.gameHistory.DragonWinPercent,
                TigerWinPercent: action.gameHistory.TigerWinPercent,
                TiePercent: action.gameHistory.TiePercent,
                SuitedTiePercent: action.gameHistory.SuitedTiePercent
            }
        default:
            return state;
    }
}