import {
    SET_DISABLE_BETS_AREAS
} from "@actions/actionTypes";

export default function enabledBetAreasReducer(state = false, action) {
    switch (action.type) {
        case SET_DISABLE_BETS_AREAS:
            return action.isEnabled;
        default:
            return state;
    }
}
