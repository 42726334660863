import {SET_LIMIT} from "@actions/actionTypes";
import initialStore from "../initialStore";

function limitReducer(state = initialStore.limit, action) {
    switch (action.type) {
        case SET_LIMIT:
            return {
                Min: action.limit.Min,
                Max: action.limit.Max,
                Nominal: action.limit.Nominal || []
            }
        default:
            return state;
    }
}

export default limitReducer