// export const DefaultLanguage = 'en-US';
export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];


export const BetsType = {
    1: "win",
    2: "lose",
    3: "refund",
    "win": 1,
    "lose": 2,
    "refund": 3
}

export const TimeTicker = {
    6: "yellow",
    3: "red",
    0: "white"
}


export const SocketUpdateType = {
    0: "Ping",
    1: "Error",
    2: "RoundStart",
    3: "Card",
    4: "Odd",
    5: "Timer",
    6: "Result",
    7: "Bet",
    8: "Pair"
}

export const CardsSuit = {
    0: "spades",
    1: "clubs",
    2: "hearts",
    3: "diamonds"
}


export const PlayerOddType = {
    0: "None",
    1: "Dragon",
    2: "Tiger",
    3: "Tie",
    4: "SuitedTie",
    "None": 0,
    "Dragon": 1,
    "Tiger": 2,
    "Tie": 3,
    "SuitedTie": 4
}
export const Prediction = {
    "Undefined": 0,
    "DragonWin": 1,
    "TigerWin": 2,
    "Tie": 3,
    "SuitedTie": 4,
    0: "Undefined",
    1: "DragonWin",
    2: "TigerWin",
    3: "Tie",
    4: "SuitedTie"
}

export const gameHistory = {
    0: "Undefined", //UP
    1: "D", //DOWN
    2: "T",
    3: "T",
    4: "T",
    "Undefined": 0,
    "DragonWin": 1,
    "TigerWin": 2,
    "Tie": 3,
    "SuitedTie": 4
}

export const gameHistoryColor = {
    0: "Undefined", //UP
    1: "DragonWin", //DOWN
    2: "TigerWin",
    3: "Tie",
    4: "SuitedTie",
    "Undefined": 0,
    "DragonWin": 1,
    "TigerWin": 2,
    "Tie": 3,
    "SuitedTie": 3
}

export const CardSide = {
    2: "tiger",
    1: "dragon",
    "tiger": 2,
    "dragon": 1
}

export const OwnerTypes = {
    dealer: 0,
    player: 1,
    "0": "dealer",
    "1": "player"
}



export const CardName = [
    "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardTypeMap = {
    1: "A",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "A",
    15: "Joker1",
    16: "Joker2"
}

export const CardType = {
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    "10": 10,
    "J": 11,
    "Q": 12,
    "K": 13,
    "A": 14,
    "Joker1": 15,
    "Joker2": 16
}

export const winnerEnum = {
    0: "undefined",
    1: "dragonWin",
    2: "tigerWin",
    3: "tie",
    4: "suitedTie",
    undefined: 0,
    dragonWin: 1,
    tigerWin: 2,
    tie: 3,
    suitedTie: 4
}

export const PairType = {
    0: "None",
    1: "Player",
    2: "Banker",
    3: "Both",
    "None": 0,
    "Player": 1,
    "Banker": 2,
    "Both": 3,
}

export const SocketReadyState = {
    "CONNECTING": 0,
    "OPEN": 1,
    "CLOSING": 2,
    "CLOSED": 3
}