// import initialStore from "../initialStore";
// import {
//     SET_ODDS
// } from "@actions/actionTypes";

// export default function oddsReducer(state = initialStore.odds, action) {
//     switch (action.type) {
//         // case SET_ODDS:
//         //     return action.odds;
//         default:
//             return state;
//     }
// }

import initialStore from "../initialStore";

export default function oddReducer(state= initialStore.odd) {
    return state
}