import React, {useEffect, useState} from "react";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import classNames from "classnames";

function CardsListItem({card, ...restProps}) {
    const [cardItem, setCarditem] = useState({
        suit: 0,
        type: 0
    })

    useEffect(() => {
        let timer = setTimeout(() => {
            setCarditem(card);
        }, 500)
        return () => {
            clearTimeout(timer);
        }
    }, [card])
    return(
        <div className="cards-list-item">
            {/* <div className="card-point">
                {card.point}
            </div> */}
            <div className="cards-list-item card" {...restProps}>
                <div className={classNames("flip-card", {active: cardItem.type})}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src={cardBg} alt={cardBg} />
                        </div>
                        <div className="flip-card-back">
                            <img src={openedCardBg} alt={openedCardBg} />
                            <i className={`cardIcon icon-${cardItem ? cardItem.type + cardItem.suit : cardItem }`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CardsListItem;