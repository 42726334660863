import React, { useEffect } from "react";
import { connect } from "react-redux";
import {subscribeCards} from "@actions/cardsAction";
import CardList from "./CardsList";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";

function Cards({dragonCard, tigerCard, subscribeCards, threeD}) {

    useEffect(() => {
        subscribeCards();
    }, [])

    return (
        <div className={classNames("cards", {threeD})}>
            <div className="cards-body">
                <CardList card={dragonCard} title={<FormattedMessage id="dragon"/>}/>
                <CardList card={tigerCard} title={<FormattedMessage id="tiger"/>} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        dragonCard: state.dragonCard,
        tigerCard: state.tigerCard,
    }
}

const mapDispatchToProps = {
    subscribeCards
}


export default connect(mapStateToProps, mapDispatchToProps)(Cards);