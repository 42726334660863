import React from "react";
import classNames from "classnames";
import { gameHistory, gameHistoryColor } from "@constants/Enums";

function GameHistoryIcon({ won, type, active }) {
    return (
        <div className={classNames("game-history-icon", type, gameHistoryColor[won], { active: active })}>
            {gameHistory[won]}
        </div>
    )
}

export default GameHistoryIcon